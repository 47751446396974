<template>
    <div class="container">


        <div class="uk-width-1-1 uk-container uk-margin-large-top">
          <div class=" uk-margin-auto uk-text-center">
            <h3>{{item.title}}</h3>
            <div class="uk-margin">
              <div class="uk-inline " v-html="item.detail">
              </div>
            </div>
          </div>
        </div>


    </div>
</template>

<script>
import DashboardLayout from "@/view/layouts/DashboardLayout";
import store from "@/core/services";

import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEM,
  GET_ITEM,
  LOADING
} from "@/core/services/store/agreement.module";

export default {
  name: "AGREEMENT",
  components: {
    DashboardLayout
  },
  data(){
   
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) {},
    },
    assignment_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) {},
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {},
    },

  },
  methods: {
    getAgreementResult() {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEM, {
        url: BASE_URL
      })
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getAgreementResult, 500);
  },
  mounted() {
    this.getAgreementResult();
  },
}
</script>

<style scoped>

</style>